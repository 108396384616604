<template>
    <Toast/>
    <div class="grid">
        <div class="col-12">
            <div class="card text-center" v-if="permissionDenied">
                <h4>A kért oldal megtekintéséhez nincs jogosultsága!</h4>
            </div>
            <div class="card" v-if="!permissionDenied">
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                            <Button label="Törlés" icon="pi pi-trash" class="p-button-danger"
                                    @click="confirmDeleteSelected"
                                    :disabled="!selectedAdmins || !selectedAdmins.length"/>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="admins" v-model:selection="selectedAdmins" dataKey="id" :paginator="true"
                           :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['name','email','roleWithText']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Adminisztrátorok <br> <strong><small>Összesen: {{ total }}</small></strong>
                            </h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:5%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="name" header="Név" :sortable="true" headerStyle="width:20%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Név</span>
                            {{ slotProps.data.name }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="email" header="E-mail cím" :sortable="true" headerStyle="width:20%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">E-mail cím</span>
                            {{ slotProps.data.email }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="createdAt" header="Regisztráció dátuma" :sortable="true"
                            headerStyle="width:20%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Regisztráció dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column field="roleWithText" header="Jogosultság" :sortable="true"
                            headerStyle="width:20%; min-width:10rem;" :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Jogosultság</span>
                            <span class="product-badge status-instock" v-if="slotProps.data.roleWithText === 'admin'">Adminisztrátor</span>
                            <span class="product-badge status-lowstock" v-else>Munkatárs</span>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="roles"
                                      placeholder="Összes"
                                      class="p-column-filter" :showClear="true" optionLabel="label" optionValue="value">
                                <template #value="slotProps">
                                <span v-if="slotProps.value">
                                    <span v-if="slotProps.value === 'admin'">Adminisztrátor</span>
                                    <span v-else>Munkatárs</span>
                                </span>
                                    <span v-else>{{ slotProps.placeholder }}</span>
                                </template>
                                <template #option="slotProps">
                                    {{ slotProps.option.label }}
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <ConfirmPopup></ConfirmPopup>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                    v-if="user.id !== slotProps.data.id"
                                    v-tooltip.top="'Szerkesztés'" @click="edit(slotProps.data)"/>
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-primary mr-2"
                                    v-if="user.id !== slotProps.data.id"
                                    v-tooltip.top="'Új jelszó küldése'" @click="sendNewPassword($event, slotProps.data.id)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2"
                                    @click="confirmDelete(slotProps.data)" v-tooltip.top="'Törlés'"
                                    v-if="user.id !== slotProps.data.id"/>
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen adminisztrátor sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="adminDialog" :style="{width: '600px'}" header="Adminisztrátor adatai"
                        :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="name">Név</label>
                        <InputText id="name" v-model.trim="admin.name" required="true"
                                   :class="{'p-invalid': submitted && !admin.name}"/>
                        <small class="p-invalid" v-if="submitted && !admin.name">Név megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="email">E-mail cím</label>
                        <InputText id="email" v-model.trim="admin.email" required="true"
                                   :class="{'p-invalid': submitted && !admin.email}"/>
                        <small class="p-invalid" v-if="submitted && !admin.email">E-mail cím megadása kötelező.</small>
                    </div>

                    <div class="field" v-if="!admin.id">
                        <label for="password">Jelszó</label>
                        <Password id="password" v-model.trim="admin.password" required="true" weakLabel="Gyenge"
                                  promptLabel="Adjon meg egy jelszót" mediumLabel="Közepes" strongLabel="Erős"
                                  :class="{'p-invalid': submitted && !admin.password}">
                            <template #footer>
                                <Divider />
                                <p class="mt-2">Követelmények</p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li>Minimum 1 kisbetű</li>
                                    <li>Minimum 1 nagybetű</li>
                                    <li>Minimum 1 szám</li>
                                    <li>Minimum 8 karakter</li>
                                </ul>
                            </template>
                        </Password>
                        <small class="p-invalid" v-if="submitted && !admin.password">Jelszó megadása kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="role" class="mb-3">Jogosultság</label>
                        <Dropdown id="role" :modelValue="admin.roleWithText" v-model="admin.roleWithText"
                                  :options="roles"
                                  optionLabel="label" optionValue="value" placeholder="Jogosultság"
                                  :class="{'p-invalid': submitted && !admin.roleWithText}">
                            <template #value="slotProps">
                                <span v-if="slotProps.value">
                                    <span v-if="slotProps.value === 'admin'">Adminisztrátor</span>
                                    <span v-else>Munkatárs</span>
                                </span>
                                <span v-else>
									{{ slotProps.placeholder }}
								</span>
                            </template>
                        </Dropdown>
                        <small class="p-invalid" v-if="submitted && !admin.roleWithText">Jogosultság megadása
                            kötelező.</small>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveAdmin" :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteAdminDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span
                            v-if="admin">Biztosan törölni szeretné a következő felhasználót: <br> <b>{{ admin.name }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteAdminDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteAdmin"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteAdminsDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span v-if="admin">Biztosan törölni szeretné a kiválasztott adminisztrátorokat?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteAdminsDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteSelectedAdmins"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import AdminService from "@/service/AdminService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            user: null,
            total: 0,
            admins: null,
            adminDialog: false,
            deleteAdminDialog: false,
            deleteAdminsDialog: false,
            admin: {},
            selectedAdmins: null,
            filters: {},
            submitted: false,
            loading: true,
            permissionDenied: true,
            roles: [
                {label: 'Adminisztrátor', value: 'admin'},
                {label: 'Munkatárs', value: 'colleague'}
            ]
        }
    },
    adminService: null,
    created() {
        this.user = JSON.parse(localStorage.getItem('userData'));
        this.adminService = new AdminService();
        this.initFilters();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));

        if (user.role === 1) {
            this.permissionDenied = true;
        } else {
            this.permissionDenied = false;
            this.getAll();
        }
    },
    methods: {
        getAll() {
            this.adminService.getAll().then(response => {
                this.total = response.data.total
                this.admins = response.data.data
                this.loading = false;
            });
        },
        openNew() {
            this.admin = {};
            this.submitted = false;
            this.adminDialog = true;
        },
        hideDialog() {
            this.adminDialog = false;
            this.submitted = false;
        },
        saveAdmin() {
            this.submitted = true;

            if (
                !this.admin.name ||
                !this.admin.email ||
                !this.admin.roleWithText
            ) {
                return
            }

            if (this.admin.name.trim()) {
                if (this.admin.id) {
                    this.loading = true;

                    this.adminService.update(this.admin.id, {
                        email: this.admin.email,
                        name: this.admin.name,
                        role: this.admin.role,
                    }).then(() => {
                        this.adminDialog = false;
                        this.admin = {};
                        this.getAll()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az új adminisztrátor adatai sikeresen módosultak.',
                            life: 5000
                        });
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                } else {
                    if (!this.admin.password) {
                        return
                    }

                    this.loading = true;

                    this.adminService.create({
                        name: this.admin.name,
                        email: this.admin.email,
                        password: this.admin.password,
                        role: this.admin.roleWithText === 'admin' ? 0 : 1,
                    }).then(() => {
                        this.adminDialog = false;
                        this.admin = {};
                        this.getAll()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az új adminisztrátor mentése sikeres volt.',
                            life: 5000
                        });


                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                }
            }
        },
        edit(admin) {
            this.admin = {...admin};
            this.adminDialog = true;
        },
        sendNewPassword(event, id) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan új jelszót szeretne generálni ennek az felhasználónak?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.adminService.generatePassword(id).then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az adminisztrátornak sikeresen kiküldtünk egy új jelszót.',
                            life: 5000
                        });
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });
                    });
                }
            });
        },
        confirmDelete(admin) {
            this.admin = admin;
            this.deleteAdminDialog = true;
        },
        deleteAdmin() {
            this.delete();
            this.deleteAdminDialog = false;
        },
        delete() {
            this.loading = true;

            this.adminService.delete(this.admin.id).then(() => {
                this.admin = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'Az adminisztrátor sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.admins.length; i++) {
                if (this.admins[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Név', key: 'name', width: 40 },
                { header: 'E-mail cím', key: 'email', width: 40 },
                { header: 'Regisztráció dátuma', key: 'createdAt', width: 20 },
                { header: 'Jogosultság', key: 'role', width: 20 }
            ];

            const selectedProperties = worksheet.columns.map(column => column.key);
            const data = this.admins === null ? [] : this.admins;
            const modifiedData = data.map(obj =>
                selectedProperties.reduce((acc, prop) => {
                acc[prop] = obj[prop];
                return acc;
                }, {})
            );

            modifiedData.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const roleColumnIndex = selectedProperties.indexOf('role');
            if (roleColumnIndex !== -1) {
                worksheet.getColumn(roleColumnIndex + 1).eachCell((cell, rowNumber) => {
                if (rowNumber > 1) {
                    cell.value = cell.value === 0 ? 'Adminisztrátor' : 'Munkatárs';
                }
                });
            }

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_adminisztratorok.xlsx`;
            a.download = filename;
            a.click();
        },
        confirmDeleteSelected() {
            this.deleteAdminsDialog = true;
        },
        deleteSelectedAdmins() {
            this.loading = true;

            this.selectedAdmins.map((admin) => {
                this.admin = admin;
                this.adminService.delete(this.admin.id);
            });

            this.deleteAdminsDialog = false;

            this.$toast.add({
                severity: 'success',
                summary: 'Sikeres művelet!',
                detail: 'Az adminokk törlése sikeres volt.',
                life: 5000
            });

            this.getAll()
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'roleWithText': {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS
                },
                'email': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'name': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>
